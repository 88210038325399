import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSession, sessionId } from "./features/userSlice";
import { useHistory } from "react-router-dom";
import Chat from "./Chat";
import "./ChatWindow.css";

const ChatWindow = ({ sessionId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [id, setID] = useState("");
  const [loading, setLoading] = useState(true);
  const params = new URLSearchParams();
  const [show, setShow] = useState(false);
  console.log("session", sessionId);
  console.log(sessionId.userSessionID);
  //   console.log(temp.userSessionID);

  const showID = () => {
    setShow(!show);
  };

  const seeMessages = (e) => {
    // dispatch(sessionId(sessionId.userSessionID));
    setID(sessionId.userSessionID);
    history.push(`/chat/${sessionId.userSessionID}`);
  };
  // console.log("Req params", req.params);
  if (sessionId.userEmail !== "") {
    return (
      <div
        className="ChatWindow"
        key={sessionId._id}
        onClick={seeMessages}
        // style={{
        // 	border: "3px solid black",
        // 	padding: "10px",
        // 	margin: "20px",
        // 	cursor: "pointer",
        // }}
      >
        {sessionId?.messagesArray?.map((message) => {
          <Chat key={id} message={message} />;
        })}
        <div className="userdetails">
          <h4 className="user">{sessionId.userName}</h4>
          <h4 className="user">{sessionId.userEmail}</h4>
          <h4 className={show ? "user" : `user userID`} onClick={showID}>
            {sessionId.userSessionID}
          </h4>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ChatWindow;
