import React, { useEffect, useState } from "react";
import "./Chat.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectSession } from "./features/userSlice";
import { useHistory } from "react-router-dom";
import ChatTable from "./ChatTable";
import ChatConvo from "./ChatConvo";

const Chat = ({ message, id, match }) => {
  const url =
    "https://sleepmedicineassitantbackend-dot-sleepmedicine.uc.r.appspot.com/userData";
  const params = new URLSearchParams();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [topics, setTopics] = useState({});
  const [selectedTopic, setSelectedTopic] = useState("problem falling asleep");

  useEffect(async () => {
    await axios
      .post(`${url}/${match.params.userSessionID}`)
      .then((res) => {
        let messagesSection = {
          "problem falling asleep": [],
          "early morning arousal": [],
          "problem staying asleep": [],
          "unusual behavior during sleep": [],
          "daytime fatigue": [],
        };
        let data = res.data.data.messagesArray;
        // console.log("data", data);
        let problemfallingasleepStartIndex = 0;
        let problemfallingasleepEndIndex = 0;

        let problemstayingasleepStartIndex = 0;
        let problemstayingasleepEndIndex = 0;

        let earlymorningarousalStartIndex = 0;
        let earlymorningarousalEndIndex = 0;

        let daytimefatigueStartIndex = 0;
        let daytimefatigueEndIndex = 0;

        let unusualbehaviorduringsleepStartIndex = 0;
        let unusualbehaviorduringsleepEndIndex = 0;
        function determineIndexes(data, topicValue) {
          let StartIndex = 0;
          let EndIndex = 0;
          for (let i = 0; i < data.length; i++) {
            if (
              data[i].msg &&
              data[i].msg.payload &&
              data[i].msg.payload.fields &&
              data[i].msg.payload.fields.PLATFORM_sleepmedicine &&
              data[i].msg.payload.fields.PLATFORM_sleepmedicine.structValue
                .fields.conversationtopic.stringValue
            ) {
              if (
                data[
                  i
                ].msg.payload.fields.PLATFORM_sleepmedicine.structValue.fields.conversationtopic.stringValue.toLowerCase() ==
                topicValue.toLowerCase()
              ) {
                if (StartIndex == 0) {
                  StartIndex = i - 1;
                } else {
                  EndIndex = i > StartIndex ? i : StartIndex;
                }
              }
            }
          }

          //   console.log("star index", StartIndex);
          //   console.log("end index", EndIndex);
          return [StartIndex, EndIndex];
        }

        [problemfallingasleepStartIndex, problemfallingasleepEndIndex] =
          determineIndexes(data, "problem falling asleep");
        [earlymorningarousalStartIndex, earlymorningarousalEndIndex] =
          determineIndexes(data, "early morning arousal");
        [problemstayingasleepStartIndex, problemstayingasleepEndIndex] =
          determineIndexes(data, "problem staying asleep");
        [daytimefatigueStartIndex, daytimefatigueEndIndex] = determineIndexes(
          data,
          "daytime fatigue"
        );
        [
          unusualbehaviorduringsleepStartIndex,
          unusualbehaviorduringsleepEndIndex,
        ] = determineIndexes(data, "unusual behavior during sleep");

        function pushdata(topic, startIndex, endIndex) {
          console.log("indexes and topic", topic, startIndex, endIndex);
          console.log("messages for topic", messagesSection[`${topic}`]);
          for (let i = startIndex; i < endIndex; i++) {
            messagesSection[`${topic}`].push(data[i]);
          }
        }
        pushdata(
          "problem falling asleep",
          problemfallingasleepStartIndex,
          problemfallingasleepEndIndex
        );
        pushdata(
          "problem staying asleep",
          problemstayingasleepStartIndex,
          problemstayingasleepEndIndex
        );
        pushdata(
          "early morning arousal",
          earlymorningarousalStartIndex,
          earlymorningarousalEndIndex
        );
        pushdata(
          "daytime fatigue",
          daytimefatigueStartIndex,
          daytimefatigueEndIndex
        );
        pushdata(
          "unusual behavior during sleep",
          unusualbehaviorduringsleepStartIndex,
          unusualbehaviorduringsleepEndIndex
        );
        console.log("messages section", messagesSection);
        setTopics(messagesSection);
        setMessages(res.data.data);
      })
      .catch((err) => err.message);
    setLoading(false);
    // console.log(match.params.userSessionID);
  }, []);
  useEffect(() => {
    console.log("seleted topic ", selectedTopic);
  }, [selectedTopic]);

  useEffect(() => {
    console.log("TOPICSSSSSZS", topics);
  }, [topics]);
  return (
    <div className="chatBox">
      {/* <h1>{selectMessage.userSessionID}</h1> */}
      {/* <p>{selectMessage._id}</p> */}
      <div className="chat">
        <h1 className="chatHeading">CONVERSATION</h1>
        <hr style={{ marginBottom: "20px" }} />
        {loading && <h1 className="loading">Loading . . . .</h1>}
        <div className="topics">
          <span
            className={
              selectedTopic == "problem falling asleep"
                ? "highlighted-topic"
                : ""
            }
            onClick={(e) => setSelectedTopic("problem falling asleep")}
          >
            Problem falling asleep
          </span>
          <span
            onClick={(e) => setSelectedTopic("problem staying asleep")}
            className={
              selectedTopic == "problem staying asleep"
                ? "highlighted-topic"
                : ""
            }
          >
            Problem Staying Asleep
          </span>
          <span
            onClick={(e) => setSelectedTopic("early morning arousal")}
            className={
              selectedTopic == "early morning arousal"
                ? "highlighted-topic"
                : ""
            }
          >
            Early Morning Arousal
          </span>
          <span
            onClick={(e) => setSelectedTopic("daytime fatigue")}
            className={
              selectedTopic == "daytime fatigue" ? "highlighted-topic" : ""
            }
          >
            Daytime Fatigue
          </span>
          <span
            onClick={(e) => setSelectedTopic("unusual behavior during sleep")}
            className={
              selectedTopic == "unusual behavior during sleep"
                ? "highlighted-topic"
                : ""
            }
          >
            Unusual Behavior During Sleep
          </span>
        </div>
        <div className="chatMessages">
          {/* <h3>Conversation</h3> */}

          {topics[`${selectedTopic}`] &&
            topics[`${selectedTopic}`].length > 0 && (
              <ChatConvo
                className="individualconvo"
                topic={selectedTopic}
                messages={topics[`${selectedTopic}`]}
              />
            )}
          {topics[`${selectedTopic}`] &&
            !topics[`${selectedTopic}`].length > 0 && (
              <p className="noChats">No Chats Avaiable</p>
            )}
        </div>
      </div>
      {/* {console.log(message)} */}

      <ChatTable messages={messages} loading={loading} />
    </div>
  );
};

export default Chat;
