import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { hashHistory } from "react-router";

import { createHashHistory } from "history";
import "./Login.css";
import { useDispatch } from "react-redux";
import { login } from "./features/userSlice";
import { FooterParent } from "./components/Footer/Footer.style";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  // const history = createHashHistory();
  const history = useHistory();
  console.log("THI IS PROPS", props);
  const loginUser = (e) => {
    e.preventDefault();

    fetch(
      "https://sleepmedicineassitantbackend-dot-sleepmedicine.uc.r.appspot.com/signin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    )
      .then((res) => {
        // console.log()
        return res.json();
      })
      .then((res) => {
        console.log("This is RESPONSE", res.token);
        // if (res.status === 400 || res.status === 500) {
        // 	window.alert("Invalid credentials");
        // 	console.log("Login unsuccessful");
        // 	setError(true);
        // }
        // else {
        // 	console.log("resres", res);
        // 	console.log("Login successful");
        if (res && res.token && res.login) {
          let token = res.token;
          dispatch(
            login({
              email,
              token,
            })
          );
          localStorage.setItem("userData", JSON.stringify(email));
          localStorage.setItem("token", JSON.stringify(token));

          props.history.push("/dashboard");
          window.location.reload();
        } else {
          // window.alert("Invalid credentials");
          console.log("Login unsuccessful");
          setError(true);
        }
      })
      .catch((err) => console.log(err));

    // const data = res.json();
    // console.log(data);
    // if (res.status === 400 || res.status === 500) {
    // 	window.alert("Invalid credentials");
    // 	console.log("Login unsuccessful");
    // } else {
    // 	// window.alert("Login successful");
    // 	console.log("Login successful");
    // 	// console.log(data);
    // 	dispatch(login(email));
    // 	// console.log(data.token);
    // 	props.history.push("/dashboard");
    // }
    console.log(email, password);
    // props.history.push("/dashboard");
  };

  return (
    <div className="login">
      <p style={{ fontSize: 40, marginTop: 80 }}>Admin Login</p>
      {error && <h3 className="error">Please check your credentials</h3>}
      <form>
        <label style={{ fontSize: 18 }} htmlFor="email">
          E-Mail
        </label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
        />
        <label style={{ fontSize: 18 }} htmlFor="password">
          Password
        </label>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
        <button className="login__button" onClick={loginUser}>
          Sign In
        </button>
      </form>
      <FooterParent></FooterParent>
    </div>
  );
};

export default withRouter(Login);
