import { createSlice } from "@reduxjs/toolkit";

// const initialState

export const userSlice = createSlice({
	name: "user",
	initialState: {
		email: null,
		token: null,
	},
	reducers: {
		login: (state, action) => {
			state.email = action.payload.email;
			state.token = action.payload.token;
			// state.token = action.payload;
			// console.log("THIS IS THE ACTION", action);
			console.log("THIS IS STATE / ACTION", state);
			// localStorage.setItem("userData", JSON.stringify(state.email));
			// localStorage.setItem("token", JSON.stringify(state.token));
			// console.log("action.payload.data.user", state.email);
		},
		logout: (state) => {
			// state.user = null;
			state.user = null;
			// state.token = null;
			localStorage.removeItem("userData");
			localStorage.removeItem("token");
		},
	},
});

export const { login, logout } = userSlice.actions;

// export const selectUser = (state) => state.user.user;
export const selectEmail = (state) => state.user.email;

export default userSlice.reducer;
