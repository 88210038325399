import React, { useEffect, useState } from "react";

// import React from 'react'

export default function ChatConvo({ messages, topic }) {
  console.log("TOPIC IS", topic);
  console.log("MESSAGES ARE", messages);
  return (
    <div>
      {messages?.map((message) => {
        if (
          !(
            message.msg.payload &&
            message.msg.payload.fields &&
            message.msg.payload.fields.PLATFORM_sleepmedicine
          )
        ) {
          return (
            <>
              <p className={message.speaks === "bot" ? "left" : "right"}>
                <div className="dropShadow">{message.msg.text.text}</div>
              </p>
              <p className={message.speaks === "me" && "showTime"}>
                {message.time}
              </p>
            </>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}
