import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./emailparameters.css";
import { selectEmail } from "../../features/userSlice";
const EmailParameters = (props) => {
  console.log("mounting");
  const email = useSelector(selectEmail);
  console.log("crrentemail", email);
  const url =
    "https://sleepmedicineassitantbackend-dot-sleepmedicine.uc.r.appspot.com/changeEmailParameters";
  const [localemail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recieverEmail, setRecieverEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const changeHandler = async (e) => {
    e.preventDefault();
    console.log("reciever", recieverEmail);
    setLoading(true);
    const res = await axios.post(url, {
      senderNewEmail: localemail,
      senderNewPassword: password,
      //   recieverEmail: recieverEmail,
      //   currentEmail,
    });
    setLoading(false);
    setEmail("");
    setPassword("");
    console.log("res", res);
  };

  return (
    <div className="change-email-params">
      <h2 style={{ textAlign: "center" }}>
        Change the credentials of the email address that sends out notification
      </h2>
      <input
        autoComplete="nope"
        type="email"
        placeholder="Enter sender email's address here"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      ></input>
      <input
        autoComplete="nope"
        type="password"
        placeholder="Enter sender email's password here"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      ></input>

      <button onClick={(e) => changeHandler(e)}>
        {loading ? "Loading" : "Change"}
      </button>
    </div>
  );
};
export default EmailParameters;
