import React, { useEffect, useState } from "react";
import "./SessionId.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ChatWindow from "./ChatWindow";
import { login, selectUser } from "./features/userSlice";

const SessionId = () => {
  const [sessionId, setSessionId] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams();
  const [loading, setLoading] = useState(true);
  // const genurl = "http://localhost:5000/allAdminData";
  const genurl =
    "https://sleepmedicineassitantbackend-dot-sleepmedicine.uc.r.appspot.com/allAdminData";
  const url =
    "https://sleepmedicineassitantbackend-dot-sleepmedicine.uc.r.appspot.com/userData/439d03a4-ed81-424a-addc-a486c876978d6414ada2-5004-4f3d-9739-f7c4c47914c4";
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  // console.log(sessionId);
  useEffect(() => {
    console.log("THIS IS SESSION ID");
    axios
      .get(genurl)
      .then((res) => {
        console.log(res.data);
        setSessionId(res.data);
      })
      .catch((err) => err.message);
    setLoading(false);
  }, []);

  return (
    <div className="sessionId">
      <h2 style={{ fontSize: 35 }}>Doctor Dashboard</h2>
      <h5 style={{ fontSize: 18 }}>(List Of Conversations)</h5>
      <div className="sessionWindow">
        <h3 className="sessionWindow__heading">Name</h3>
        <h3 className="sessionWindow__heading">Email</h3>
        <h3 className="sessionWindow__heading">SessionID</h3>
      </div>
      {loading && <h1>Loading</h1>}
      {sessionId?.map((sessionId) => {
        return <ChatWindow sessionId={sessionId} loading={loading} />;
      })}
    </div>
  );
};

export default SessionId;
