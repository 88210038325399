import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { NavLink, Link, useLocation } from "react-router-dom";
import { login, logout, selectEmail } from "./features/userSlice";
import "./Header.css";
import image from "./images/Avatar.jpeg";
import logoImage from "./images/EditedLogo.png";

const Header = (props) => {
  const location = useLocation();
  console.log("location", location.pathname);
  const dispatch = useDispatch();
  // const name = useSelector(selectEmail);
  const history = useHistory();
  // const user = useSelector(selectEmail);
  const email = useSelector(selectEmail);
  const [headerEmail, setHeaderEmail] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  let header = "";

  const logoutPopUp = () => {
    if (email !== null) setShowPopUp(true);
  };

  const logoutUser = () => {
    dispatch(logout());
    window.location.reload();
    props.history.push("/");
  };

  const cancelLogoutUser = () => {
    setShowPopUp(false);
  };

  useEffect(() => {
    setHeaderEmail(email);
    header = setHeaderEmail(JSON.parse(localStorage.getItem("userData")));
  }, [header]);
  return (
    <div className="header">
      <div className="header__logoSection">
        <NavLink to="/">
          <img className="header__logoImage" src={logoImage} alt="" />
        </NavLink>
      </div>

      <div className="header__midSection">
        <h2 className="company__name">
          Sleep Disorder Diagnostic AI Assistant
        </h2>

        {/* <p className="header__midSectionSubHeading">Somnology Tech LLC</p> */}
      </div>
      <div className="header__details">
        <div>
          {location.pathname == "/dashboard" && (
            <Link to="/email-parameters">Change Email Address</Link>
          )}
          {location.pathname == "/email-parameters" && (
            <Link to="/dashboard">Dashboard</Link>
          )}
        </div>
        <img
          className="logo"
          // className={email ? "logo" : "noLogo"}
          src={
            email !== null
              ? image
              : "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
          }
          alt=""
          onClick={logoutPopUp}
        />

        {showPopUp && (
          <div className="logout_wrapper">
            <div className="logout__popup">
              <h3>Do you want to logout</h3>
              <div className="logout__popupButtons">
                <button className="logout__confirm" onClick={logoutUser}>
                  Confirm
                </button>
                <button className="logout__cancel" onClick={cancelLogoutUser}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Header);
