import React, { useEffect } from "react";
import "./App.css";
import SessionId from "./SessionId";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import ChatWindow from "./ChatWindow";
import Chat from "./Chat";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { login, selectEmail, selectUser } from "./features/userSlice";
import Login from "./Login";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";
import Footer from "./components/Footer";
import EmailParameters from "./components/EmailParameters";

function App(props) {
  const email = useSelector(selectEmail);
  console.log("email in app", email);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    console.log("app.js");
    //   console.log("Pr",props)
    if (localStorage.getItem("userData")) {
      dispatch(login(JSON.parse(localStorage.getItem("userData"))));
    }
  }, []);

  return (
    <>
      <Fragment className="app">
        <Header />
        {email !== null ? (
          <Switch>
            <Route exact path="/chat/:userSessionID" component={Chat}></Route>
            <Route exact path="/chatwindow" component={ChatWindow}></Route>
            <Route exact path="/dashboard" component={SessionId}></Route>
            <Route
              exact
              path="/email-parameters"
              component={EmailParameters}
            ></Route>
            <Redirect to={"/dashboard"} from={"/"} />
            {/* <Route exact path="/login" component={Login}></Route> */}
          </Switch>
        ) : (
          <Switch>
            <Route path="/" component={Login} exact></Route>
            <Redirect to={"/"} from={props.location.pathname} />
          </Switch>
        )}
      </Fragment>
    </>
  );
}

export default withRouter(App);
