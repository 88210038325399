import React from "react";
import { useState } from "react";
import "./ChatTable.css";

const ChatTable = ({ messages, loading }) => {
	// const [loading, setLoading] = useState(true);
	return (
		<div className="chatTable">
			<h1 className="chattableheading">Potential Diagnosis Results </h1>
			<hr style={{ marginBottom: "20px" }} />
			<div className="chatTable__data">
				<table className="user-data-table">
					<thead>
						<tr className="theadrow">
							<td className="firstColumn">Problem</td>
							<td className="secondColumn">Symptom</td>
							<td className="thirdColumn">Potential Diagnosis</td>
						</tr>
					</thead>

					<tbody>
						{messages?.diagnosisArray?.map((message) => {
							return (
								<>
									{/* {console.log(message)} */}

									<tr className="tableData">
										<td className="firstColumn">
											{message.root.toLowerCase().includes("parasomnia")
												? "Unusual Behavior During Sleep"
												: message.root}
										</td>
										<td className="secondColumn">{message.basedOn}</td>
										<td className="thirdColumn">{message.diagnosisValue}</td>
									</tr>
								</>
							);
						})}
					</tbody>
				</table>
				{loading && <h1 className="loading">Loading . . . . </h1>}
			</div>
		</div>
	);
};

export default ChatTable;
